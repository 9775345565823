import { ClerkProvider } from '@clerk/react-router';
import { rootAuthLoader } from '@clerk/react-router/ssr.server';
import { Provider as JotaiProvider } from 'jotai';
import { isRouteErrorResponse, Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from 'react-router';
import { UnhandledError } from '~/components/page-layout/unhandled-error-page';
import { Route } from './+types/root';
import './global-styles/tailwind.css';
import { Layout as RootLayout } from './layouts/root-layout';

export const loader = (args: Route.LoaderArgs) => rootAuthLoader(args);

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <UnhandledError title={error.statusText} statusCode={error.status} description={JSON.stringify(error.data)} />
    );
  } else if (error instanceof Error) {
    return <UnhandledError title="Something unexpected happened" statusCode={500} description={error.message} />;
  } else {
    return (
      <UnhandledError title="Something unexpected happened" statusCode={500} description={JSON.stringify(error)} />
    );
  }
};

export function Layout({ children }: { children: React.ReactNode }) {
  const shouldRenderGA = import.meta.env.PROD;
  const gtagId = 'G-YB7GYLH0RK';
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {shouldRenderGA && <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`} />}
        {shouldRenderGA && (
          <script
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtagId}');
        `,
            }}
          />
        )}
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App({ loaderData }: Route.ComponentProps) {
  return (
    <ClerkProvider loaderData={loaderData} signUpFallbackRedirectUrl="/" signInFallbackRedirectUrl="/">
      <JotaiProvider>
        <RootLayout>
          <Outlet />
        </RootLayout>
      </JotaiProvider>
    </ClerkProvider>
  );
}
