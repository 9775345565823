import { Typography } from '@naamdeo/shadcn-ui/components/typography';
import { Suspense } from 'react';
import { Await, Link } from 'react-router';
import { CourseCard, CourseCardSkeleton } from '~/components/course-card';
import { ContentSection } from '~/components/page-layout/content-section';
import { getSeoMeta } from '~/lib/seo';
import { wrapPromise } from '~/lib/ts-util';
import { Route } from './+types/_index';

export const meta: Route.MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Discover',
    },
    // @ts-expect-error - this is a hack to pass the args to getSeoMeta
    args
  );
};

export const loader = (args: Route.LoaderArgs) => {
  const availableCourses = wrapPromise(args.context.cloudflare.env.PUBLIC_DATA_SERVICE.listAvailableCourses());
  return {
    availableCourses,
  };
};

export default function Index({ loaderData }: Route.ComponentProps) {
  const availableCoursesPromise = loaderData.availableCourses;
  return (
    <>
      <ContentSection>
        <Typography variant="h1" className="mb-12 text-primary">
          Course Catalog
        </Typography>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <Suspense
            fallback={
              <>
                <CourseCardSkeleton />
                <CourseCardSkeleton />
                <CourseCardSkeleton />
              </>
            }
          >
            <Await resolve={availableCoursesPromise}>
              {(availableCourses) =>
                availableCourses.map((course) => (
                  <Link key={course.id} to={`/course/${course.machineSlug}`}>
                    <CourseCard
                      title={course.title}
                      thumbnail={`https://public-assets.naamdeo.org/videos/${course.machineSlug}/thumbnail.jpg`}
                      slug={course.machineSlug}
                      chapters={course.lessonCount}
                      durationSeconds={course.durationSeconds}
                    />
                  </Link>
                ))
              }
            </Await>
          </Suspense>
        </div>
      </ContentSection>
    </>
  );
}
