import { useLocation } from 'react-router';

export function useParentPath(): string {
  const location = useLocation();
  const pathname = location.pathname;
  // Remove trailing slashes
  const cleanedPathname = pathname.replace(/\/$/, '');
  // Find the last "/" and get the substring up to that point
  const parentPath = cleanedPathname.substring(0, cleanedPathname.lastIndexOf('/')) || '/';
  return parentPath;
}
