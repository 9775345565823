import { Badge } from '@naamdeo/shadcn-ui/components/badge';
import { Button } from '@naamdeo/shadcn-ui/components/button';
import { Separator } from '@naamdeo/shadcn-ui/components/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@naamdeo/shadcn-ui/components/sheet';
import { Typography } from '@naamdeo/shadcn-ui/components/typography';
import { useAtom } from 'jotai';
import { ChevronRightIcon, ShoppingCartIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { Form } from 'react-router';
import { cartAtom } from '~/state/cart';

export function CartSheet() {
  const [cart, cartDispatch] = useAtom(cartAtom);
  const [sheetOpen, setSheetOpen] = useState(false);

  const handleRemoveItem = useCallback(
    (machineSlug: string) => {
      cartDispatch({ type: 'remove', item: { machineSlug } });
    },
    [cartDispatch]
  );
  const handleContinueBrowsing = useCallback(() => setSheetOpen(false), [setSheetOpen]);

  const CurrencyFormatter = Intl.NumberFormat(undefined, { style: 'currency', currency: 'INR' });

  if (!cart.items.length) {
    return null;
  }

  return (
    <>
      <Sheet onOpenChange={setSheetOpen} open={sheetOpen}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="relative">
            <ShoppingCartIcon />
            <Badge variant="destructive" className="absolute -right-2 -top-2 h-6 w-6 rounded-full p-2">
              {cart.items.length}
            </Badge>
          </Button>
        </SheetTrigger>
        <SheetContent className="w-full sm:max-w-xl">
          <SheetHeader className="mb-8">
            <SheetTitle className="lg:text-xl">Cart ({cart.items.length})</SheetTitle>
            <SheetDescription className="hidden">Shopping cart</SheetDescription>
            <Separator />
          </SheetHeader>
          <div className="flex flex-col gap-8">
            {cart.items.map((item) => (
              <div key={item.priceId} className="flex flex-row items-center justify-between rounded-lg bg-gray-200 p-4">
                <img
                  src={`https://public-assets.naamdeo.org/videos/${item.machineSlug}/thumbnail.jpg`}
                  alt={item.name}
                  className="aspect-[16/10] w-40 rounded-lg object-cover"
                />
                <div className="w-full pl-8 text-left">
                  <Typography variant="largeText">{item.name}</Typography>
                  <Typography>{CurrencyFormatter.format(29)}</Typography>
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  className="px-1"
                  onClick={handleRemoveItem.bind(null, item.machineSlug)}
                >
                  <Trash2Icon />
                </Button>
              </div>
            ))}
          </div>
          <div className="mt-8 flex flex-col gap-4">
            <Typography variant="largeText" className="text-right">
              Total: {CurrencyFormatter.format(29 * cart.items.length)}
            </Typography>
            <Form method="POST" action={`/api/cart`} reloadDocument>
              {cart.items.map((item) => (
                <input key={item.machineSlug} type="hidden" name="priceIds[]" value={item.priceId} required />
              ))}
              <Button className="w-full text-lg" type="submit">
                Check out now <ChevronRightIcon />{' '}
              </Button>
            </Form>
            <Button variant="outline" onClick={handleContinueBrowsing}>
              Continue Browsing
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
}
